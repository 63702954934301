/* Auth.css */

.auth-container-auth {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.auth-container-auth h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group-auth {
  margin-bottom: 15px;
}

.form-group-auth label {
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-weight: bold;
}

.form-group-auth input {
  width: calc(100% - 22px); /* Ensures consistent padding */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.form-group-auth input:focus {
  border-color: #007bff;
  outline: none;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.auth-container-auth p {
  text-align: center;
  margin-top: 20px;
  color: #555;
}

.auth-container-auth a {
  color: #007bff;
  text-decoration: none;
}

.auth-container-auth a:hover {
  text-decoration: underline;
}



/* Auth.css */

.auth-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.auth-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-weight: bold;
}

.form-group input {
  width: calc(100% - 22px); /* Ensures consistent padding */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.auth-container p {
  text-align: center;
  margin-top: 20px;
  color: #555;
}

.auth-container a {
  color: #007bff;
  text-decoration: none;
}

.auth-container a:hover {
  text-decoration: underline;
}
