/* ServicePage.css */

/* body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}
.container {
  display: flex;
  height: 100%;
}
.left-side {
  width: 30%;
  background-color: #f0e6e1;
  padding: 20px;
  box-sizing: border-box;
}
.right-side {
  width: 70%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
.yoga-class {
  display: flex;
  margin-bottom: 20px;
}
.yoga-class img {
  width: 200px;
  height: auto;
  margin-right: 20px;
}
.yoga-info {
  flex-grow: 1;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #8e7c94;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-right: 10px;
} */



/* SchedulePage.css */



.booking-modal {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  padding: 20px;
  max-width: 1000px;
}

.booking-modal h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.booking-modal p {
  display: block;
  text-align: center;
  margin-bottom: 20px;
  color: #666;
}

.horizontal-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.calendar-wrapper, .time-slots-wrapper, .service-details-wrapper {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 10px;
  flex: 1;
  min-width: 250px;
}

h3 {
  margin-top: 0;
  color: #333;
}

.timezone-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.timezone-wrapper select {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.time-slots {
  list-style-type: none;
  padding: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
  display: grid;
}

.time-slots li {
  margin-bottom: 10px;
}

.time-slots button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  background-color: #007BFF;
  color: #fff;
  transition: background-color 0.3s ease;
}

.time-slots button.selected {
  background-color: #0056b3;
}

.time-slots button:hover {
  background-color: #0056b3;
}

.accordion-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button:hover {
  background-color: #0056b3;
}

.service-details-content {
  padding: 10px 0;
  color: #666;
}

.next-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  width: 100%;
}

.next-button:hover {
  background-color: #218838;
}

.arrow {
  font-size: 12px;
  margin-left: 10px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

 
/* BookingForm.css */

.booking-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust as needed */
  margin: 0 auto; /* Center the form horizontally */
}

.booking-form label {
  margin-bottom: 5px;
}

.booking-form input,
.booking-form textarea,
.booking-form button {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.booking-form input[type='checkbox'] {
  margin-bottom: 5px;
}

.booking-form button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.booking-form button:hover {
  background-color: #0056b3;
}
.booking-form {
  max-width: 400px; /* Adjust as needed */
  margin: 0 auto; /* Center the form horizontally */
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type='text'],
.form-group input[type='email'],
.form-group textarea[type='text'],
.form-group input[type='tel'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-group input[type='checkbox'] {
  margin-right: 5px;
}
.form-group textarea[type='checkbox']{
  margin-right: 5px;
}
.booking-form-container {
  display: flex;
  justify-content: space-between;
}

.booking-form {
  flex: 0 1 48%; /* Adjust as needed */
}

.Booking-details-wrapper {
  flex: 0 1 25%;
  margin-top: 25px;
  margin-right: 100px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .booking-form-container {
    flex-direction: column;
   
  }

  .booking-form,
  .booking-details-wrapper {
    width: 100%;
  }
}


/* 
bookingSchduale.css */


.booking-summary-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.booking-summary-container h2 {
  margin-top: 0;
  font-weight: bold;
  color: #333;
}

.service-details, .client-details {
  margin-bottom: 20px;
}

.service-details p, .client-details p {
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
}

.buttons-container {
  margin-top: 20px;
  text-align: center;
}

/* Add styles for the back button */
.back-button {
  background-color: #4CAF50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #3e8e41;
}



/* index.css */


