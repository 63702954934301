.footer {
    background-color: #2b2222;
    color: white;
    padding: 40px;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding-bottom: 20px;

  }
  
  .contact-info {
    width: 700px;
    display: flex;
    gap: 50px;
  }
  .contact-info ul {
    list-style: none;
    padding: 0;
  }
  

  .contact-info li {
    margin-bottom: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .contact-info h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  
  .social-icons {
    margin-top: 20px;
    display: inline-flex;
    align-items: flex-start;
    gap: 24px;
  }
  
  .social-icons svg {
    font-size: 24px;
    cursor: pointer;
  }
  
  address {
    align-self: stretch;
    color: var(--base-color-text-primary, #F0F0F0);

/* Body/Body XXS */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 900px;
    line-height: 24px; /* 150% */
    margin-top: 0px;
    font-style: normal;
    margin-bottom: 20px;

    
  }
  .span {

    color: var(--base-color-text-primary, #F0F0F0);

    /* Body/Body XXS */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px; /* 150% */
  }

  .contact-button {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start; /* Aligns the button to the left */
  }
  
  .contact-button:hover {
    background-color: white;
    color: #2b2222;
  }
  
  .footer-links {
    
    display: flex;
    gap: 50px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    color: var(--link-color-default, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .footer-links li:hover {
    color: #ddd;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  
  .footer-bottom p {
    color: var(--base-color-text-primary, #F0F0F0);

    /* Body/Body XXS Bold */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  
  .footer-legal {
    color: var(--link-color-default, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    list-style: none;
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .footer-legal li {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .footer-legal li:hover {
    color: #ddd;
  }
  



  @media screen and (max-width: 768px) {
    .footer {
      padding: 80px 20px;
      height: auto;
    }
  
    .footer-top {
      flex-direction: column;
      padding-bottom: 120px;
      border-bottom: 1px solid var(--base-color-divider-mid, #FFF);;

    
    }
  
    .contact-info {
      width: 100%;
      flex-direction: column;
      gap: 20px;
    }

    .contact-info h1 {
      color: var(--base-color-text-primary, #F0F0F0);

      /* Title/Subheading 3 */
      font-family: "Playfair Display";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 150% */
    }
  
  
    .contact-info li {
      margin-bottom: 10px;
    }
  
    address {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  
    .span {
      font-size: 14px;
      line-height: 30px;
    }
  
    .social-icons {
      display: inline-flex;
      align-items: flex-start;
      gap: 24px;
      
    }
    .social-icons svg {
      width: 24px;
      height: 23.661px;
      flex-shrink: 0;
    }
  
    .contact-button {
      
      margin-top: 20px;
      display: inline-flex;
      padding: 16px 20px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 32px;
      border: 1px solid var(--button-color-border-default, #FFF);
    }
  
  /* Footer Links */
  .footer-links {
    display: flex;
    justify-content: left;
    margin: 20px 0;
    gap: 20px;
    flex-wrap: wrap;
  }

  .footer-links ul {
    list-style: none;
    padding: 0;
  }

  .footer-links ul li {
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--link-color-default, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  .footer-links ul li:hover {
    color: #fff;
    text-decoration: underline;
  }
  
    .footer-bottom {
      flex-direction:column;
     
      text-align: left;
    }
  
    .footer-bottom {
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
     
    }
  
    .footer-legal {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      margin-bottom: 10px;
      width: 100%;
    }
  
    .footer-legal li {
      font-size: 16px;
      line-height: 24px;
    }
  
    .footer-bottom p {
      color: var(--base-color-text-primary, #F0F0F0);

      /* Body/Body XXS Bold */
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      margin-top: 10px;
      order: 1;
    }
  }