/* :root {
    --background: 0, 0%, 100%;
    --foreground: 0, 0%, 0%;
    --primary: 240, 100%, 50%;
    --primary-foreground: 0, 0%, 100%;
    --secondary: 120, 100%, 50%;
    --secondary-foreground: 0, 0%, 100%;
    --card: 0, 0%, 95%;
    --card-foreground: 0, 0%, 0%;
    --input: 0, 0%, 90%;
    --border: 0, 0%, 80%;
    --ring: 0, 0%, 70%;
    --muted: 0, 0%, 60%;
    --muted-foreground: 0, 0%, 50%;
  } */

  /* body {
    font-family: 'Inter', sans-serif;
    background-color: hsl(var(--background));
    color: hsl(var(--foreground));
  } */
  
  .hero-cobra {
    position: relative;
    background-image: url('/public/images/cobra-03.jpg');
    background-size: cover;
    background-position: center;
    height: 440px;
    flex-shrink: 0;
    display: flex;
    margin-top: 110px;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .hero-cobra::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(51, 45, 45, 0.4); /* Dark overlay with transparency */
    z-index: 1;
  }
  
  .hero-cobra * {
    position: relative;
    z-index: 2; /* Ensure text and other elements appear above the overlay */
  }
  
  .logo-cobra {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg) scale(0.80); /* Scale the logo down */
    z-index: 1;
    background: transparent; /* Fully transparent background */
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    opacity: .8;
  }
  
  .logo-cobra img {
  
    flex-shrink: 0;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(90%); /* Image filter */
    clip-path: inherit; /* Ensure the image uses the same clip-path */
  }
  
  
  
  
  .hero-text-cobra {
    position: absolute;
    top: 75%;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .hero-text-cobra h1 {
    width: 940px;
    color: var(--base-color-text-primary, #F0F0F0);
    text-align: center;
    
    /* Title/Display */
    font-family: "Playfair Display";
    font-size: 75px;
    font-style: normal;
    font-weight: 600;
    line-height: 100px; /* 133.333% */
  }
  
  .hero-text-cobra p {
    font-size: 1.5rem;
    margin: 10px 0 20px;
  }
  
  .hero-text-cobra a {
    padding: 15px 30px;
    border: 2px solid #fff;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    font-size: 1rem;
    display: inline-block;
  }
  
  .hero-text-cobra a:hover {
    background-color: #007bff; /* Changes background color on hover */
    color: #fff; /* Changes text color on hover */
  }

  button {
    transition: background-color 0.3s ease;
  }

  .bg-primary {
    background-color: hsl(var(--primary));
  }

  .text-primary-foreground {
    color: hsl(var(--primary-foreground));
  }

  .bg-secondary {
    background-color: hsl(var(--secondary));
  }

  .text-secondary-foreground {
    color: hsl(var(--secondary-foreground));
  }

  .bg-card {
    background-color: hsl(var(--card));
  }

  .text-card-foreground {
    color: hsl(var(--card-foreground));
  }

  .bg-input {
    background-color: hsl(var(--input));
  }

  .text-muted-foreground {
    color: hsl(var(--muted-foreground));
  }

  .text-primary {
    color: hsl(var(--primary));
  }

  .hover\:bg-primary\/80:hover {
    background-color: hsl(var(--primary) / 0.8);
  }

  .hover\:bg-secondary\/80:hover {
    background-color: hsl(var(--secondary) / 0.8);
  }


  .rounded-first {
    border-radius: 32px 0px 0px 32px;
    border: 1px solid var(--base-color-border-secondary, #B1B1B1);
    background: var(--base-color-bg-tertiary, #F3ECE2);
  }
  .rounded-second {
    border-radius: 0px 32px 32px 0px;
    border: 1px solid var(--base-color-border-secondary, #B1B1B1);
    background: var(--base-color-bg-primary, #FCFAF4);
  }
  .shadow {
    border: 1px solid var(--base-color-border-primary, #A29AAE);
  }

  .p-4 {
    padding: 1rem; /* 16px */
  }

  .p-6 {
    padding: 1.5rem; /* 24px */
    background: var(--base-color-bg-primary, #FCFAF4);
    overflow: hidden;
    position: relative;
  }

  .px-2 {
    padding-left: 0.5rem; /* 8px */
    padding-right: 0.5rem; /* 8px */
  }

  .px-4 {
    padding-left: 1rem; /* 16px */
    padding-right: 1rem; /* 16px */
  }

  .py-2 {
    padding-top: 0.5rem; /* 8px */
    padding-bottom: 0.5rem; /* 8px */
    
  }

  .mt-2 {
    margin-top: 0.5rem; /* 8px */
  }

  .mt-4 {
    margin-top: 1rem; /* 16px */
  }

  .mb-4 {
    margin-bottom: 1rem; /* 16px */
  }

  .mb-6 {
    margin-bottom: 1.5rem; /* 24px */

  }
  .mb-6 h2 {
    margin-top: 50px;
  }

  .text-center {
    text-align: center;
  }

  .text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
  }

  .text-xl {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem; /* 28px */
  }
.p-4 h3 {
  padding: 20px;
  justify-content: center;
  align-items: center;
}
  .text-sm {
    color: var(--base-color-text-primary, #332D2D);
    text-align: center;
    
    /* Body/Body XS */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px; /* 187.5% */
  }

  .font-semibold {
    color: var(--base-color-text-primary, #332D2D);


    /* Body/Body XS Bold */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px; /* 187.5% */
  }

  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-center {
    align-items: center;
   
    
  }
  .border-button {
    
    width: 895px;
    margin-left: 525px;
    top: 0%;
   
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mr-2 {
    width: 24px;
    height: 24px;
    /* 8px */
    margin-bottom: 100px;
  }

  /* .ml-2 {
    margin-left: 0.5rem; 
  } */

  .max-w-4xl {
    max-width: 56rem; /* 896px */
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
.border-button {
  width: 895px;
  border-top: 1px solid black;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 697px;
}
  .scrollable {
    max-height: 1000px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollable::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
  .display {
    display: flex;
    flex-direction: column;
  }
.shadow h3 {
  
  color: var(--base-color-text-primary, #332D2D);
  text-align: center;

  /* Title/Heading 4 */
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  width: 850px;
  padding-bottom: 20px;
  padding-left:20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.mx-2 {
  color: var(--base-color-text-primary, #332D2D);
  text-align: center;

  /* Title/Subheading 2 */
  font-family: "Playfair Display";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 128.571% */
}
.border-button button {
  border: none;
  background-color: transparent;

}
.border-button button img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.scrollable h4 {
  padding: 0;
  color: var(--base-color-text-primary, #332D2D);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 100% */
  background: var(--base-color-bg-secondary, #F9F6EE);
  background-color:var(--base-color-bg-secondary, #F9F6EE);;
  
}
.scrollable {
  background: var(--base-color-bg-secondary, #F9F6EE);
}
.border-mt-flex {
  background: var(--base-color-bg-primary, #FCFAF4);

}
.border-flex-item-center {
  background: var(--base-color-bg-primary, #FCFAF4);
}
.border-mt-flex {

  
 
 
  
}
.border-flex-item-center {
  color: var(--base-color-text-primary, #332D2D);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px; /* 100% */
  margin-top: 10px;
  margin-left: 0px;
  text-align: left;

 
}
.para-font a {
  color: var(--link-color-default, #332D2D);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  
  
}
.para-font a img {
  margin-bottom: 0px;
  height: 10px;
  width: 20px;
}

.rounded {
  display: flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  border: 2px solid var(--button-color-border-default, #7B7092);
}
  /* monthly css */
  /* body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f5f5f5;
} */
.calendar {
  width: 1140px;
  border: 1px solid var(--base-color-border-primary, #A29AAE);
  background: var(--base-color-bg-primary, #FCFAF4);
  margin-right: 0px; 
}

.center-container {
  
  width: auto;
  max-width: 1440px;
 
  
  /* Flexbox centering */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.p-6 {
 
  margin: 0 auto; /* Centers the container horizontally */
  padding: 30px;
  
}

.cleander-header {
  color: var(--base-color-text-primary, #332D2D);
  text-align: center;

  /* Title/Heading 4 */
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 125% */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-bottom: 1px solid #ddd;
  gap: 50px;
}

.flex-items-center-first {
  display: flex;
  flex-direction: row;
  width: auto;
  height: 90px;
  gap: 10px;
  margin: 0px;
  padding: 0px;
}

.flex-items-center-first p {
  color: var(--base-color-text-primary, #332D2D);

  /* Body/Body XXS Bold */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.flex-items-center-first p span {
  color: var(--base-color-text-tertiary, #777);
  font-family: Roboto;
  font-size: 10px;
  font-style: italic;
  font-weight: 300;
  line-height: 20px; /* 166.667% */
}

/* .flex-items-center-first h5 {
  margin-top: 0px;
  color: var(--base-color-text-secondary, #525252);

 
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 0px; 
} */

.flex-items-center-first h5 {
  margin-top: 10px; /* Adjust top margin */
  margin-bottom: 10px; /* Add bottom margin for spacing */
  color: var(--base-color-text-secondary, #525252);

  /* Typography */
  font-family: Roboto, Arial, sans-serif; /* Fallback fonts */
  font-size: 14px; /* Slightly larger for desktop readability */
  font-style: normal;
  font-weight: 400; /* Slightly bolder for clarity */
  line-height: 1.5; /* Set to 150% of font size for readability */

  /* Responsive adjustments for desktop */
  @media (min-width: 768px) {
    font-size: 14px; /* Increase font size for larger screens */
    line-height: 1.6; /* Slightly increased line-height for better spacing */
  }
}


.flex-items-center-first img {
  width: 24px;
  height: 24px;
}

.month {
  font-size: 24px;
  font-weight: bold;
}

.days {
  color: var(--base-color-text-secondary, #525252);

  /* Body/Body S Bold */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
  flex: 1 0 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0px;
  background: var(--base-color-bg-primary, #FCFAF4);
}

.day-name {
  background: var(--base-color-bg-primary, #FCFAF4);
  text-align: center;
  padding: 10px;
  font-weight: bold;
}

.day {
  background: var(--base-color-bg-primary, #FCFAF4);
  width: 151.3px;
  min-height: 144px;
  padding: 5px;
  border: 0.5px solid var(--base-color-border-primary, #A29AAE);
}

.date {
  font-weight: bold;
  margin-bottom: 5px;
}

.event {
  font-size: 12px;
  margin-bottom: 3px;
}

.morning {
  color: #ffa500;
}

.evening {
  color: #6495ed;
}


/* weekely css */
/* Schedule.css */
.button-active {

  border: 0.5px solid var(--base-color-border-secondary, #B1B1B1);
  background: var(--base-color-bg-tertiary, #F3ECE2);
}

.button-inactive {
  background-color: #f1f1f1; /* Inactive button background color */
  color: black; /* Inactive button text color */
}




.decorative-p-6-left,
.decorative-p-6-right {
  position: absolute;
  bottom: 150px;
  width: 340px;
  height: 340px;
  flex-shrink: 0; /* Adjust based on your layout needs */
  border-radius: 50%; 
  /* Add a circular shape to the container */
}

.decorative-p-6-left {
  transform: translate(-55%, -300%);
  opacity: 0.3;
 
}

.decorative-p-6-right {
  right: 180px;
  transform: translate(35%, 45%);
  translate: 160px 100px;
  opacity: 0.3;
}

.decorative-p-6-left img,
.decorative-p-6-right img {
  width: 350px;
  height: 350px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 50%; /* Inherit the circular shape from the container */
  filter: brightness(1.5); /* Increase brightness by 20% */
}


/* Mobile view adjustments */
@media (max-width: 768px) {
  .hero-cobra {
    height: 300px; /* Adjust the height for smaller screens */
    margin-top: 70px; /* Reduce margin for mobile */
    background-size: cover; /* Ensure background is covered properly */
    background-position: center;
    padding-left: 0px;
    margin-left: 0px;
  }

  .hero-text-cobra {
    top: 65%; /* Adjust the position of the text for mobile */
    transform: translate(-50%, -50%);
    padding: 0 20px; /* Add padding for better spacing */
  }

  .hero-text-cobra h1 {
    width: 200px;
    font-size: 40px; /* Reduce font size for mobile */
    line-height: 50px;
  }

  .hero-text-cobra p {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }

  .hero-text-cobra a {
    padding: 10px 20px; /* Smaller button for mobile */
    font-size: 0.9rem;
  }

  .logo-cobra {
    top: 0%; /* Adjust logo position for mobile */
    transform: translate(-50%, -50%) rotate(90deg) scale(0.6); /* Scale down the logo */
  }
  .logo-cobra img {
    width: 200px;
    height: 200px;
  }

  .hero-cobra::before {
    background: rgba(51, 45, 45, 0.5); /* Darker overlay for better contrast */
  }
}



/* Mobile styles for screen widths 768px or less */
@media (max-width: 768px) {
 .p-6 {
  
  
 }

  .decorative-p-6-left,
  .decorative-p-6-right {
    display: none; /* Hides decorative elements on mobile for more space */
  }

  .max-w-4xl {
    max-width: 100%; /* Full width on mobile */
    padding: 0;
  }

  .text-lg {
    font-size: 1rem; /* 16px for smaller text size */
  }

  .text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
  }

  .text-xl {
    font-size: 1.125rem; /* 18px */
  }



  .rounded-second.ml-2 {
    margin-left: 0; /* Remove margin between buttons on mobile */
    margin-top: 8px; /* Stack buttons vertically */

  }

  .px-4, .py-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .p-6 {
    padding: 16px; /* Reduce padding for mobile */
  }



  .space-y-4 {
    gap: 0.5rem; /* Reduce gap between elements */
  }

  .scrollable {
    max-height: 500px; /* Reduce height on mobile */
  }

  .border-button {
   width: 107%;
   margin-right: 525px;
  }

  .border-button button img {
    width: 20px;
    height: 20px;
  }

 
  .mx-auto {
    margin-left: 0;
    margin-right: 0;
  }
  .para-font p,
  .para-font a {
    font-size: 14px;
  }

  .button-active,
  .button-inactive {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
.rounded-lg h3 {
  width: 100%;
}
.border-mt-flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;
}
.rounded {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 32px;
  border: 2px solid var(--button-color-border-default, #7B7092);
}
.mx-2 {
  font-size: 22px;
}

  
}







/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .center-container {
    padding: 4px;
    width: 100%;
    overflow-x: hidden;
  }

  .calendar {
    box-shadow: none;
    width: 100%;
    margin: 0;
  }

  .calendar-header {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .month {
    font-size: 1rem;
    text-align: center;
    flex: 1;
  }

  .calendar-header img {
    width: 18px;
    height: 18px;
  }

  .days {
    display: grid;
    grid-template-columns: repeat(7, minmax(40px, 1fr));
    gap: 1px;
    padding: 2px;
    width: 98%;
  }

  .day-name {
    padding: 4px 2px;
    font-size: 0.7rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .day {
    min-height: 60px;
    max-height: 80px;
    padding: 2px;
    font-size: 0.75rem;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .date {
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    padding: 2px;
    position: sticky;
    top: 0;
    background: white;
  }

  .event {
    margin: 1px 0;
    font-size: 0.7rem;
    max-width: 100%;
  }

  .flex-items-center-first {
    padding: 2px;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    background-color: #f8f9fa;
    border-radius: 2px;
    margin: 1px;
  }

  .flex-items-center-first > div {
    flex: 1;
    min-width: 0; /* Allows text to shrink */
  }

  .event img {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
  }

  .event p {
    margin: 0;
    font-size: 0.65rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event h5 {
    margin: 0;
    font-size: 0.65rem;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .event span {
    font-size: 0.6rem;
  }
  /* Handle overflow for events */
.day {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  max-width: 100%;
}

.day::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}


}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .days {
    grid-template-columns: repeat(7, minmax(35px, 1fr));
  }

  .day {
    min-height: 50px;
    max-height: 70px;
  }

  .day-name {
    font-size: 0.65rem;
    padding: 2px 1px;
  }

  .flex-items-center-first {
    padding: 1px;
    margin: 1px 0;
  }

  .event p, 
  .event h5 {
    font-size: 0.6rem;
  }

  .date {
    font-size: 0.7rem;
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, minmax(40px, 1fr));
    gap: 1px;
    padding: 2px;
    width: 98%;
  }

  /* Handle overflow for events */
.day {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  max-width: 100%;
}

.day::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

}

/* Extra Small Mobile Devices */
@media (max-width: 320px) {
  .days {
    grid-template-columns: repeat(7, minmax(30px, 1fr));
  }
  
  .days {
    display: grid;
    grid-template-columns: repeat(7, minmax(40px, 1fr));
    gap: 1px;
    padding: 2px;
    width: 90%;
  }

  .day {
    min-height: 45px;
    max-height: 60px;
  }

  .event p, 
  .event h5 {
    font-size: 0.55rem;
  }
  /* Handle overflow for events */
.day {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.day::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}


}

/* Landscape Mode */
@media (max-width: 768px) and (orientation: landscape) {
  .days {
    grid-template-columns: repeat(7, 1fr);
  }

  .day {
    min-height: 80px;
    max-height: 100px;
  }

  .event p, 
  .event h5 {
    font-size: 0.7rem;
  
  }

  /* Handle overflow for events */
.day {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.day::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

}

