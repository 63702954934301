src/components/Profile.css

.profile-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-container h1 {
    margin-bottom: 20px;
  }
  
  .profile-container label {
    display: block;
    margin-bottom: 10px;
  }
  
  .profile-container input {
    padding: 8px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .profile-container button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    color: white;
    background-color: #007bff;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .profile-container button:hover {
    background-color: #0056b3;
  }
  
  .profile-container button:disabled {
    background-color: #6c757d;
  }
  