.table-container {
    margin-top: 20px;
    overflow-x: auto;
  }
  .table-container h1 {
    text-align: center;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .table thead {
    background-color: #4CAF50;
    color: white;
  }
  
  .table th {
    padding: 12px 15px;
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  
  .table td {
    padding: 12px 15px;
    text-align: center;
    font-size: 1rem;
    color: #555;
  }
  
  .table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .table tbody tr:hover {
    background-color: #f8f9fa;
    cursor: pointer;
  }
  
  .table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #fff;
  }

  section h3 {
    padding: 10px;
  }
  
  @media (max-width: 768px) {
    .table thead {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #343a40;
    }
  
    .table tbody tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
    }
  
    .table td {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem;
      text-align: right;
      border-bottom: 1px solid #eee;
    }
  
    .table td::before {
      content: attr(data-label);
      font-weight: bold;
      margin-right: 1rem;
      text-align: left;
      min-width: 130px;
    }
  
    .table td:last-child {
      border-bottom: none;
    }
  
    .table th {
      display: none;
    }
  }