/* * {
  background-color:#f9f9f9 ;
} */
body {
  background-color: #f9f9f9;

}
  /* MyAccount.css */
.account-container {
  display: flex;
  font-family: Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  padding: 20px 20px 308px;
  justify-content: center;
  margin-top: 10rem;
  
}

.sidebar {
  width: 25%;
  background-color: #fff;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px 0;
  cursor: pointer;
  color: #555;
}

.sidebar ul li.active {
  font-weight: bold;
  color: #0073e6;
}

.details-container {
  width: 75%;
  padding: 20px;
}

.breadcrumbs {
  color: #777;
  font-size: 0.9rem;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.form-section {
  margin-bottom: 30px;
}

.form-section h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.form-section p {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.save-button {
  background-color: #0073e6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #005bb5;
}





.bookings-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 1rem;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.bookings-table thead {
  background-color: #4CAF50;
  color: white;
}

.bookings-table th,
.bookings-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bookings-table tr:hover {
  background-color: #f1f1f1;
}

.bookings-table td {
  color: #555;
}

.bookings-table th {
  font-weight: bold;
}

.error {
  color: red;
  font-size: 1.1rem;
  text-align: center;
  margin: 20px 0;
}

/* h2 {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
  margin: 20px 0;
}

p {
  font-size: 1rem;
  color: #777;
  text-align: center;
} */

/* Mobile-first media queries */
@media screen and (max-width: 768px) {
  .account-container {
    flex-direction: column;
    padding: 15px;
    margin-top: 5rem;
  }

  .sidebar {
    width: 90%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }

  .details-container {
    width: 100%;
    padding: 15px 0;
  }

  .sidebar h2 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .sidebar ul li {
    padding: 8px 0;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .form-section {
    margin-bottom: 20px;
  }

  .form-section h3 {
    font-size: 1.1rem;
  }

  .form-group input {
    padding: 8px;
  }

  /* Responsive table styles */
  .bookings-table {
    font-size: 0.9rem;
  }

  .bookings-table thead {
    display: none; /* Hide table headers on mobile */
  }

  .bookings-table tbody tr {
    display: block;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    background-color: #fff;
  }

  .bookings-table td {
    display: block;
    text-align: left;
    padding: 10px;
    position: relative;
    border-bottom: 1px solid #eee;
  }

  .bookings-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    color: #333;
  }

  .bookings-table td:last-child {
    border-bottom: none;
  }

  /* Button adjustments */
  .save-button {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .account-container {
    margin-top: 3rem;
    padding: 10px;
    margin-top: 5rem;
  }

  .form-section p {
    font-size: 0.85rem;
  }

  .breadcrumbs {
    font-size: 0.8rem;
  }

  .error {
    font-size: 0.95rem;
    margin: 15px 0;
  }
}


.cancel-button {
  background-color: #dc2626; /* Red color */
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cancel-button:hover {
  background-color: #b91c1c; /* Darker red on hover */
}

.cancel-button:active {
  background-color: #991b1b; /* Even darker red when clicked */
}

.cancel-button:disabled {
  background-color: #f87171; /* Lighter red when disabled */
  cursor: not-allowed;
}