/* Base Container Styles */
.register-container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9fafb;
    padding: 1rem;
  }
  
  .register-card {
    width: 100%;
    max-width: 28rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 2rem;
  }
  
  /* Icon Circle Styles */
  .register-icon-circle {
    width: 4rem;
    height: 4rem;
    background-color: #eef2ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem auto;
  }
  
  .register-icon {
    width: 2rem;
    height: 2rem;
    color: #4f46e5;
  }
  
  /* Header Styles */
  .register-title {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #111827;
    margin-bottom: 0.5rem;
  }
  
  .register-subtitle {
    text-align: center;
    color: #6b7280;
    margin-bottom: 2rem;
  }
  
  /* Form Styles */
  .register-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .register-names-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .register-input-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .register-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }
  
  .register-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .register-input {
    width: 100%;
    padding-left: 2.5rem;
   
    border: 1px solid #d1d5db;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #111827;
    background-color: white;
    transition: all 0.2s ease;
  }
  
  /* Special styling for inputs with icons */
  .register-input-wrapper input[name="firstName"],
  .register-input-wrapper input[name="lastName"],
  .register-input-wrapper input[name="email"]
   {
    padding-left: 2.5rem;
  }
  
  .register-input:focus {
    outline: none;
    border-color: #4f46e5;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
  }
  
  .register-field-icon {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.25rem;
    height: 1.25rem;
    color: #9ca3af;
    pointer-events: none;
  }
  
  /* Error Message Styles */
  .register-error {
    color: #dc2626;
    font-size: 0.875rem;
    text-align: center;
    margin: 0.5rem 0;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }
  
  /* Submit Button Styles */
  .register-submit {
    width: 100%;
    padding: 0.625rem 1rem;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .register-submit:hover {
    background-color: #4338ca;
    transform: translateY(-1px);
  }
  
  .register-submit:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.2);
  }
  
  .register-submit:active {
    transform: translateY(0);
  }
  
  /* Footer Styles */
  .register-footer {
    text-align: center;
    margin-top: 1.5rem;
    color: #6b7280;
  }
  
  .register-link {
    color: #4f46e5;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .register-link:hover {
    color: #4338ca;
  }
  
  /* Animations */
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 640px) {
    .register-card {
      padding: 1.5rem;
    }
    
    .register-names-row {
      grid-template-columns: 1fr;
    }
  }
  
  /* Dark Mode Styles */
  @media (prefers-color-scheme: dark) {
    .register-container {
      background-color: #111827;
    }
    
    .register-card {
      background-color: #1f2937;
    }
    
    .register-title {
      color: #f9fafb;
    }
    
    .register-subtitle {
      color: #9ca3af;
    }
    
    .register-label {
      color: #e5e7eb;
    }
    
    .register-input {
      background-color: #374151;
      border-color: #4b5563;
      color: #f9fafb;
    }
    
    .register-input:focus {
      border-color: #6366f1;
    }
    
    .register-field-icon {
      color: #9ca3af;
    }
    
    .register-footer {
      color: #9ca3af;
    }
  }