.hero-forearmstand {
  position: relative;
  background-image: url('/public/images/forearmstand-01.jpg');
  background-size: cover;
  background-position: center;
  height: 440px;
  flex-shrink: 0;
  display: flex;
  margin-top: 110px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.hero-forearmstand::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(51, 45, 45, 0.4); /* Dark overlay with transparency */
  z-index: 1;
}

.hero-forearmstand * {
  position: relative;
  z-index: 2; /* Ensure text and other elements appear above the overlay */
}

.logo4-forearmstand {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg) scale(0.80); /* Scale the logo down */
  z-index: 1;
  background: transparent; /* Fully transparent background */
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
  opacity: .8;
}

.logo4-forearmstand img {

  flex-shrink: 0;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(100%) contrast(90%); /* Image filter */
  clip-path: inherit; /* Ensure the image uses the same clip-path */
}




.hero-text-forearmstand {
  position: absolute;
  top: 75%;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-text-forearmstand h1 {
  width: 940px;
 
  color: var(--base-color-text-primary, #F0F0F0);
  text-align: center;
  
  /* Title/Display */
  font-family: "Playfair Display";
  font-size: 75px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px; /* 133.333% */
}

.hero-text-forearmstand p {
  font-size: 1.5rem;
  margin: 10px 0 20px;
}

.hero-text-forearmstand a {
  padding: 15px 30px;
  border: 2px solid #fff;
  color: #fff;
  text-decoration: none;
  border-radius: 30px;
  font-size: 1rem;
  display: inline-block;
}

.hero-text-forearmstand a:hover {
  background-color: #007bff; /* Changes background color on hover */
  color: #fff; /* Changes text color on hover */
}



.container-event {
    padding: 5rem;
    background: var(--base-color-bg-primary, #FCFAF4);
}

.header-event {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.header-event h2 {
  color: var(--base-color-text-primary, #332D2D);

  /* Title/Subheading 4 */
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}
.button-event {
  display: inline-flex;
  padding: 16px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  border: 2px solid var(--button-color-border-default, #7B7092);
}
.header-event a {
  color: var(--button-color-label-default, #332D2D);
  text-align: center;

  /* Buttons/Secondary L */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}

.events-container {
  
    height: 600px;
    overflow-y: scroll;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.events-container::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;  /* Chrome, Safari, Opera */
}

.event-first {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    transition: background-color 0.3s ease;
    position: relative;
    overflow: hidden;
}

.event-image {
    width: 320px;
    height: 238px;
    flex-shrink: 0;
    object-fit: cover;
    margin-right: 20px;
    transition: transform 0.3s ease;
}

.event-details {
    margin-top: 32px;
    flex: 1;
    transition: background-color 0.3s ease;
}

.event-title {
    color: var(--card-color-text-heading-primary, #332D2D);

    /* Title/Subheading 3 */
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
    margin-bottom: 5px;
    transition: color 0.3s ease;
}

.event-info {
  color: var(--card-color-text-text-secondary, #525252);

  /* Body/Body M Bold */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
    margin-bottom: 10px;
}
.event-details p {
  align-self: stretch;
  color: var(--card-color-text-text-secondary, #525252);

/* Body/Body XS */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px; /* 187.5% */
}

.event-actions {
    margin-right: 48px;
    margin-top: 80px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align to the right */
}

.button-event-actions-first {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  background: var(--button-color-bg-default, #7B7092);
  color: var(--button-color-label-default, #FFF);
  text-align: center;

  /* Buttons/Primary M */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 100% */
}

.button-event-actions-second {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  border: 1px solid var(--button-color-border-default, #979797);
  background: var(--button-color-bg-default, rgba(255, 255, 255, 0.00));
  color: var(--button-color-label-default, #332D2D);
  text-align: center;

  /* Buttons/Secondary M */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
}

.footer1 {
    text-align: center;
    margin-top: 20px;
}

.event-first:hover {
    background-color: var(--card-color-surface-primary, #F9F6EE); /* Change this to your preferred background color */
    width: 100%;
}

.event-image:hover {
    transform: scale(1.1);
}

.event-first:hover .event-title {
    color: #6b6b94; /* Change this to your preferred title color on hover */
    opacity: 0.5;
}

.event-actions:hover ~ .event-image {
    transform: scale(1.1);
}

.event-actions:hover ~ .event-details {
    background-color:var(--card-color-surface-primary, #F9F6EE); /* Change this to your preferred background color */
}

.event-actions:hover ~ .event-details .event-title {
    color: #ff4500; /* Change this to your preferred title color */
}

.event-actions:hover ~ .event-details .events-container {
    background-color: #f0f0f0; /* Change this to your preferred background color */
}



.registration-form {
    position: fixed;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-width: 400px;
  }
  
  .registration-form h2 {
    margin-top: 0;
  }
  
  .registration-form form {
    display: flex;
    flex-direction: column;
  }
  
  .registration-form label {
    margin-bottom: 10px;
  }
  
  .registration-form input[type="text"],
  .registration-form input[type="email"],
  .registration-form input[type="tel"] {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .registration-form .close {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .registration-form button:hover {
    background-color: #45a049;
  }
.see-past {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;


}
.see-past h1 {
  color: var(--base-color-text-primary, #332D2D);

  /* Title/Subheading 4 */
  font-family: "Playfair Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */

}
.see-past .button-event {
  color: var(--button-color-label-default, #332D2D);
  text-align: center;
  
  /* Buttons/Secondary L */
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 100% */
}

/* EventDetails Modal */
.event-details-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .event-details-modal h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .event-details-modal p {
    margin: 10px 0;
    color: #555;
    line-height: 1.6;
  }
  
  .event-details-modal .close {
    width: 100%;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
  }
  
  .event-details-modal button:hover {
    background-color: #d32f2f;
  }
  
  /* Modal Background Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  



/* Mobile view adjustments */
@media (max-width: 768px) {
  .hero-forearmstand {
    height: 300px; /* Adjust the height for smaller screens */
    margin-top: 70px; /* Reduce margin for mobile */
    background-size: cover; /* Ensure background is covered properly */
    background-position: center;
    padding-left: 0px;
    margin-left: 0px;
  
  }

  .hero-text-forearmstand {
    top: 65%; /* Adjust the position of the text for mobile */
    transform: translate(-50%, -50%);
    padding: 0 20px; /* Add padding for better spacing */
  }

  .hero-text-forearmstand h1 {
    width: 200px;
    font-size: 40px; /* Reduce font size for mobile */
    line-height: 50px;
  }

  .hero-text-forearmstand p {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }

  .hero-text-forearmstand a {
    padding: 10px 20px; /* Smaller button for mobile */
    font-size: 0.9rem;
  }

  .logo4-forearmstand {
    top: 10%; /* Adjust logo position for mobile */
    transform: translate(-50%, -50%) rotate(90deg) scale(0.6); /* Scale down the logo */
  }
  .logo4-forearmstand img {
    width: 200px;
    height: 200px;
  }

  .hero-forearmstand::before {
    background: rgba(51, 45, 45, 0.5); /* Darker overlay for better contrast */
  }
}




/* Media query for mobile devices */
@media (max-width: 768px) {
  .container-event {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .header-event {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .header-event a {
    display: none;
  }

  .header-event h2 {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
  }

  .button-event {
    width: 100%;
    padding: 14px 16px;
    font-size: 16px;
    border-radius: 24px;
    text-align: center;
  }

  .events-container {
    height: auto;
    overflow-y: visible;
  }

  .event-first {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eee;
  }

  .event-first:last-child {
    border-bottom: none;
  }

  .event-image {
    width: 100%;
    height: 200px;
    margin-right: 0;
    margin-bottom: 1rem;
    object-fit: cover;
  }

  .event-details {
    width: 100%;
    margin-top: 1rem;
  }

  .event-title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0.5rem;
  }

  .event-info {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0.5rem;
  }

  .event-details p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 1rem;
  }

  .event-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;

  }

  .button-event-actions-first,
  .button-event-actions-second {
    width: 90%;
    padding: 14px 16px;
    font-size: 16px;
    border-radius: 24px;
    text-align: center;
  }



  .see-past {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    text-align: center;
  }

  .see-past h1 {
    font-size: 18px;
    line-height: 26px;
  }

  .see-past .button-event {
    width: 100%;
    max-width: 250px;
  }

  /* Event Details Modal */
  .event-details-modal {
    width: 90%;
    max-width: 400px;
    padding: 1rem;
    border-radius: 8px;
  }

  .event-details-modal h2 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .event-details-modal button {
    width: 100%;
    font-size: 16px;
    padding: 14px 16px;
    margin-top: 1rem;
  }

  .modal-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }
}

/* Additional styles for very small screens */
@media (max-width: 320px) {
  .header-event h2,
  .event-title {
    font-size: 16px;
    line-height: 24px;
  }

  .button-event,
  .button-event-actions-first,
  .button-event-actions-second {
    font-size: 14px;
    padding: 12px 14px;
  }

  .event-info,
  .event-details p{
    font-size: 13px;
    line-height: 20px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .registration-form {
    width: 90%;
    padding: 15px;
  }

  .registration-form h2 {
    font-size: 1.25rem;
  }

  .registration-form input {
    font-size: 0.9rem;
  }

  .registration-form button {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .registration-form {
    padding: 10px;
  }

  .registration-form h2 {
    font-size: 1rem;
  }

  .registration-form label {
    font-size: 0.9rem;
  }

  .registration-form input {
    font-size: 0.8rem;
  }

  .registration-form button {
    font-size: 0.8rem;
    padding: 8px 10px;
  }
}