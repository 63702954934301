/* TestimonialSection.css */

.testimonial-container {
    /* Your existing styles */
  }
  
  .share-btn button {
    /* Style for the button */
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
  }
  
  .modal-content button.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form label {
    margin-bottom: 10px;
  }
  
  form input, form textarea {
    margin-top: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  form button {
    margin-top: 10px;
    padding: 10px;
    background-color: #ff6b6b;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  .testimonial-container {
    overflow: hidden;
  }
  
  .testimonial-slider {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .testimonial-slide {
    flex: 0 0 100%;
    box-sizing: border-box;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination span.active {
    background-color: #333;
  }
  
  .share-btn {
    text-align: center;
    margin-top: 20px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  form label {
    margin-bottom: 10px;
  }
  
  form input,
  form textarea {
    margin-top: 5px;
    padding: 5px;
  }
  
  form button {
    margin-top: 10px;
  }
  